@tailwind base;
@tailwind components;
@tailwind utilities;
@import './theme-material.scss';
@import './theme-text.scss';
@import './theme-components.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// TAILWIND COMPONENT CLASSES
@layer components {
  .shrl-button-stroked-black {
    @apply border-neutral-900 text-neutral-900;
  }
}

// GENERAL STYLING
:root {
  --swiper-pagination-color: #4b5654;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bottom: 0;
}

.mat-content-hide-toggle {
  margin-right: 0 !important;
}

// ISSUES OF -> MATERIAL - TAILWIND
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}
.mat-mdc-input-element {
  box-shadow: none !important;
}
.mat-accordion {
  ::before,
  ::after {
    border-color: unset;
  }
}
