@use '@angular/material' as mat;
@include mat.core();

// PALETTS
// 500s are the same values as in tailwind config
$blue-palette: (
  50: #e1e8ed,
  100: #b3c6d2,
  200: #80a1b5,
  300: #4d7b97,
  400: #275e80,
  500: #01426a,
  600: #013c62,
  700: #013357,
  800: #012b4d,
  900: #001d3c,
  A100: #72a8ff,
  A200: #3f89ff,
  A400: #0c6aff,
  A700: #005df1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$teal-palette: (
  50: #e3fef7,
  100: #b9feeb,
  200: #8afdde,
  300: #5bfcd0,
  400: #38fbc6,
  500: #15fabc,
  600: #12f9b6,
  700: #0ff9ad,
  800: #0cf8a5,
  900: #06f697,
  A100: #ffffff,
  A200: #ebfff6,
  A400: #b8ffe0,
  A700: #9effd5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$cyan-palette: (
  50: #e7f7fb,
  100: #c3ebf6,
  200: #9cdef0,
  300: #74d0e9,
  400: #56c6e5,
  500: #38bce0,
  600: #32b6dc,
  700: #2badd8,
  800: #24a5d3,
  900: #1797cb,
  A100: #fdfeff,
  A200: #caeeff,
  A400: #97deff,
  A700: #7ed6ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$sharelock-blue: mat.define-palette($blue-palette);
$sharelock-teal: mat.define-palette($teal-palette);
$sharelock-cyan: mat.define-palette($cyan-palette);
$sharelock-warn: mat.define-palette(mat.$red-palette);

// THEMES
$sharelock-theme-blue-cyan: mat.define-light-theme(
  (
    color: (
      primary: $sharelock-blue,
      accent: $sharelock-cyan,
      warn: $sharelock-warn,
    ),
  )
);

$sharelock-theme-blue-teal: mat.define-light-theme(
  (
    color: (
      primary: $sharelock-blue,
      accent: $sharelock-teal,
      warn: $sharelock-warn,
    ),
  )
);

$sharelock-theme-cyan-blue: mat.define-light-theme(
  (
    color: (
      primary: $sharelock-cyan,
      accent: $sharelock-blue,
      warn: $sharelock-warn,
    ),
  )
);

$sharelock-theme-cyan-teal: mat.define-light-theme(
  (
    color: (
      primary: $sharelock-cyan,
      accent: $sharelock-teal,
      warn: $sharelock-warn,
    ),
  )
);

// SET GLOBAL THEMES TO MATERIAL COMPONENTS
@include mat.stepper-theme($sharelock-theme-blue-cyan);
@include mat.progress-bar-theme($sharelock-theme-cyan-teal);
@include mat.form-field-theme($sharelock-theme-cyan-blue);
@include mat.button-theme($sharelock-theme-blue-teal);
@include mat.icon-button-theme($sharelock-theme-blue-teal);
@include mat.card-theme($sharelock-theme-blue-teal);
@include mat.autocomplete-theme($sharelock-theme-blue-teal);
@include mat.checkbox-theme($sharelock-theme-cyan-blue);
@include mat.core-theme($sharelock-theme-blue-teal);
@include mat.datepicker-theme($sharelock-theme-blue-teal);
@include mat.divider-theme($sharelock-theme-blue-teal);
@include mat.sidenav-theme($sharelock-theme-blue-teal);
@include mat.table-theme($sharelock-theme-blue-teal);
@include mat.tabs-theme($sharelock-theme-blue-teal);
@include mat.tooltip-theme($sharelock-theme-blue-teal);
@include mat.select-theme($sharelock-theme-blue-teal);
@include mat.snack-bar-theme($sharelock-theme-blue-teal);
@include mat.dialog-theme($sharelock-theme-blue-teal);
@include mat.menu-theme($sharelock-theme-blue-teal);
@include mat.chips-theme($sharelock-theme-blue-teal);
@include mat.expansion-theme($sharelock-theme-blue-teal);

// DENSITY CLASSES
.material-dense-positive-1 {
  @include mat.all-component-densities(1);
}

.material-dense-positive-2 {
  @include mat.all-component-densities(2);
}

.material-dense-positive-3 {
  @include mat.all-component-densities(3);
}

.material-dense-positive-4 {
  @include mat.all-component-densities(4);
}

.material-dense-1 {
  @include mat.all-component-densities(-1);
}

.material-dense-2 {
  @include mat.all-component-densities(-2);
}

.material-dense-3 {
  @include mat.all-component-densities(-3);
}

.material-dense-4 {
  @include mat.all-component-densities(-4);
}

// CUSTOM STYLING
.mat-mdc-unelevated-button[disabled] {
  background-color: #e8ebed !important; // shrl-gray-200
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: #ff4545;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.success-snackbar {
    --mdc-snackbar-container-color: #15fabc;
    --mat-mdc-snack-bar-button-color: #121212;
    --mdc-snackbar-supporting-text-color: #121212;
  }
  &.info-snackbar {
    --mdc-snackbar-container-color: #01426a;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
